import React from 'react'

import {
  AllYouNeedButton,
  AllYouNeedContent,
  AllYouNeedFlow,
  AllYouNeedFlowItem,
  AllYouNeedFlowTitle,
  AllYouNeedLink,
  AllYouNeedSectionWrapper,
  AllYouNeedTitle,
} from './styled'
import DesktopContainer from 'layouts/DesktopContainer'
import { DesktopScreen, MobileScreen } from 'utils/screen'
import Icon from 'components/Icon.v2'
import LINKS from 'const/links'
import LazyImage from 'pages/homepage/HeroSection/LazyImage'
import asset from 'utils/asset'

const AllYouNeedContents = ({ children }) => {
  return (
    <AllYouNeedContent>
      <AllYouNeedTitle>
        All you need in an <br /> ordering platform.
      </AllYouNeedTitle>
      <AllYouNeedFlow>
        <AllYouNeedFlowItem>
          <Icon type="check" size={20} fill="#F26722" className="block" />
          <AllYouNeedFlowTitle>
            Contactless Digital Ordering
          </AllYouNeedFlowTitle>
        </AllYouNeedFlowItem>
        <AllYouNeedFlowItem>
          <Icon type="check" size={20} fill="#F26722" className="block" />
          <AllYouNeedFlowTitle>Seamless Payments</AllYouNeedFlowTitle>
        </AllYouNeedFlowItem>
        <AllYouNeedFlowItem>
          <Icon type="check" size={20} fill="#F26722" className="block" />
          <AllYouNeedFlowTitle>Satisfied Customers</AllYouNeedFlowTitle>
        </AllYouNeedFlowItem>
      </AllYouNeedFlow>
      <AllYouNeedLink href={LINKS.LIST_BUSINESS} target="_blank">
        <AllYouNeedButton variant="primary" largeText>
          Get Started
        </AllYouNeedButton>
      </AllYouNeedLink>
      {children}
    </AllYouNeedContent>
  )
}

const AllYouNeedMobile = () => {
  return (
    <MobileScreen>
      <AllYouNeedContents>
        <LazyImage
          actualSrc={asset(
            'images/heyyou-desktop-banner-allyouneed-small.webp',
          )}
          viewType="mobile"
        />
      </AllYouNeedContents>
    </MobileScreen>
  )
}

const AllYouNeedDesktop = () => {
  return (
    <DesktopScreen>
      <LazyImage
        actualSrc={asset('images/heyyou-desktop-banner-allyouneed.webp')}
      >
        <AllYouNeedContents />
      </LazyImage>
    </DesktopScreen>
  )
}

const AllYouNeedSlide = () => {
  return (
    <AllYouNeedSectionWrapper>
      <DesktopContainer>
        <AllYouNeedMobile />
        <AllYouNeedDesktop />
      </DesktopContainer>
    </AllYouNeedSectionWrapper>
  )
}

export default AllYouNeedSlide
