import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import MainSlideV2 from './MainSlideV2/MainSlideV2'
import AllYouNeedSlide from './AllYouNeedSlide/AllYouNeedSlide'
import QrDineinSlide from './QrDineinSlide/QrDineinSlide'
import LunchFrenzySlide from './LunchFrenzySlide/LunchFrenzySlide'
import config from 'app-config'
import get from 'lodash/get'

const SliderWrapper = styled.div`
  overflow: hidden;
`

const SliderDots = styled.div`
  position: relative;
  top: -35px;

  li {
    button:before {
      font-size: 15px;
      opacity: 0.3;
      color: #f26722;
    }
  }

  .slick-active {
    button:before {
      opacity: 1;
      color: #f26722 !important;
    }
  }
`

const HeroCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: dots => (
      <SliderDots>
        <ul>{dots}</ul>
      </SliderDots>
    ),
  }

  return (
    <SliderWrapper>
      <Slider {...settings}>
        <MainSlideV2 />
        {get(config, 'LUNCH_FRENZY_ENABLED') && <LunchFrenzySlide />}
        <AllYouNeedSlide />
        <QrDineinSlide />
      </Slider>
    </SliderWrapper>
  )
}

export default HeroCarousel
