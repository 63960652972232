import React from 'react'
import styled from 'styled-components'
import colors from 'theme/colors'
import { mobileFirstMedia as media } from 'utils/media'
import Text from 'components.v2/Text'
import Button from 'components.v2/Button'

export const LunchFrenzySectionWrapper = styled.div`
  font-family: 'Montserrat', sans-serif !important;
  width: 100%;
  background-color: #f26722;
  display: flex;
  flex-direction: column;
`

export const LunchFrenzyContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 468px;
  align-items: center;

  ${media.screen`
    width: 680px;
    align-items: flex-start;
    padding: 30px;
    & > .filler {
      height: 20%;
    }
  `};

  & > .filler {
    height: 45%;
  }
`

export const LunchFrenzyTitle = styled(props => (
  <Text variant="h1" color={colors['white']} {...props} />
))`
  font-size: 28px;
  line-height: 35.2px;
  font-weight: 800;
  margin: 42px auto 10px;
  padding: 0 30px 0 30px;
  text-align: center;

  ${media.tablet`
    width: 70%;
    font-size: 24px;
    line-height: normal;
  `};

  ${media.screen`
    width: 100%;
    font-size: 50px;
    line-height: 60px;    
    margin: 128px 0px 14px 0px;
    text-align: left;
    padding: 0;
  `};
`

export const LunchFrenzyFlow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  text-align: left;

  ${media.screen`
    max-width: 100%;
    width: 100%;
    text-align: center;
  `}
`

export const LunchFrenzyFlowItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  svg {
    margin-top: 3px;
  }

  ${media.screen`
    margin-top: 20px;
    margin: 0 28px 0 0;
    
    &:last-child {
      margin-right: 0;
    }
  `}
`

export const LunchFrenzyFlowTitle = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 5px;

  ${media.screen`
    font-size: 20px;
    line-height: 32px;
  `}
`

export const LunchFrenzyLink = styled.a`
  max-width: 200px;
  width: 100%;
  margin-top: 22px;
  text-align: center;
  font-size: 12px;
  color: #ffffff;

  ${media.screen`
    max-width: 100%;
  `}
`

export const LunchFrenzyButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  max-width: 320px;
  width: 100%;
  position: relative;
  background-color: #000000;

  ${media.screen`
    font-size: 14px;
    max-width: 320px;
  `}
`
