import React from 'react'

import {
  QrDineinButton,
  QrDineinContent,
  QrDineinFlow,
  QrDineinFlowItem,
  QrDineinFlowTitle,
  QrDineinLink,
  QrDineinSectionWrapper,
  QrDineinTitle,
} from './styled'
import DesktopContainer from 'layouts/DesktopContainer'
import { DesktopScreen, MobileScreen } from 'utils/screen'
import LINKS from 'const/links'
import LazyImage from 'pages/homepage/HeroSection/LazyImage'
import asset from 'utils/asset'

const QrDineinContents = ({ children }) => {
  return (
    <QrDineinContent>
      <QrDineinTitle>QR Dine-In Experience</QrDineinTitle>
      <QrDineinFlow>
        <QrDineinFlowItem>
          <QrDineinFlowTitle>
            Scan, Order, &amp; Enjoy. Get up to 30% <br /> more trade with Hey
            You Dine-In.
          </QrDineinFlowTitle>
        </QrDineinFlowItem>
      </QrDineinFlow>
      <QrDineinLink href={LINKS.DINE_IN} target="_blank">
        <QrDineinButton variant="primary" largeText>
          Learn More
        </QrDineinButton>
      </QrDineinLink>
      {children}
    </QrDineinContent>
  )
}

const QrDineinMobile = () => {
  return (
    <MobileScreen>
      <QrDineinContents>
        <LazyImage
          actualSrc={asset('images/heyyou-desktop-banner-qrdinein-small.webp')}
          viewType="mobile"
        />
      </QrDineinContents>
    </MobileScreen>
  )
}

const QrDineinDesktop = () => {
  return (
    <DesktopScreen>
      <LazyImage
        actualSrc={asset('images/heyyou-desktop-banner-qrdinein.webp')}
      >
        <QrDineinContents />
      </LazyImage>
    </DesktopScreen>
  )
}

const QrDineinSlide = () => {
  return (
    <QrDineinSectionWrapper>
      <DesktopContainer>
        <QrDineinMobile />
        <QrDineinDesktop />
      </DesktopContainer>
    </QrDineinSectionWrapper>
  )
}

export default QrDineinSlide
