import React from 'react'
import {
  LunchFrenzyButton,
  LunchFrenzyContent,
  LunchFrenzyFlow,
  LunchFrenzyFlowItem,
  LunchFrenzyFlowTitle,
  LunchFrenzyLink,
  LunchFrenzySectionWrapper,
  LunchFrenzyTitle,
} from './styled'
import DesktopContainer from 'layouts/DesktopContainer'
import { DesktopScreen, MobileScreen } from 'utils/screen'
import LINKS from 'const/links'
import LazyImage from 'pages/homepage/HeroSection/LazyImage'
import asset from 'utils/asset'

const LunchFrenzyContents = ({ children, className }) => {
  return (
    <LunchFrenzyContent className={className}>
      <div className="filler" />
      <LunchFrenzyTitle className="text-center">
        Grab Lunch for $12.50*
      </LunchFrenzyTitle>
      <LunchFrenzyFlow>
        <LunchFrenzyFlowItem>
          <LunchFrenzyFlowTitle>
            Weekdays from 27 May - 7 June 2024
          </LunchFrenzyFlowTitle>
        </LunchFrenzyFlowItem>
      </LunchFrenzyFlow>
      <LunchFrenzyLink href={LINKS.LUNCH_FRENZY} target="_blank">
        <LunchFrenzyButton largeText>Find out more</LunchFrenzyButton>
      </LunchFrenzyLink>
      {children}
    </LunchFrenzyContent>
  )
}

const LunchFrenzyMobile = () => {
  return (
    <MobileScreen>
      <LazyImage
        actualSrc={asset('images/heyyou-desktop-banner-lunchfrenzy-small.webp')}
        viewType="mobile"
        className="full"
      >
        <LunchFrenzyContents className="mobile"></LunchFrenzyContents>
      </LazyImage>
    </MobileScreen>
  )
}

const LunchFrenzyDesktop = () => {
  return (
    <DesktopScreen>
      <LazyImage
        actualSrc={asset('images/heyyou-desktop-banner-lunchfrenzy.webp')}
      >
        <LunchFrenzyContents />
      </LazyImage>
    </DesktopScreen>
  )
}

const LunchFrenzySlide = () => {
  return (
    <LunchFrenzySectionWrapper>
      <DesktopContainer>
        <LunchFrenzyMobile />
        <LunchFrenzyDesktop />
      </DesktopContainer>
    </LunchFrenzySectionWrapper>
  )
}

export default LunchFrenzySlide
